<template>
  <div>
    <!-- tab栏 -->
    <van-tabs v-model="active">
      <van-tab title="社区活动"
        ><div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in list" :key="item.index">
            <router-link :to="'/activity/detail/' + item.AId">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImgUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div></van-tab
      >
      <van-tab title="党组活动"
        ><div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in lists" :key="item.index">
            <router-link :to="'/activity/detail/' + item.AId">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImgUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div></van-tab
      >
    </van-tabs>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WeGetMySignupActivityPage,
  WeGetMySignupActivityPages,
} from "@/api/Activity";
import { Toast } from "vant";
export default {
  data() {
    return {
      akId: 0, //tab栏
      list: {}, //资讯列表
      lists:{},
      listfrom: {}, //查询传参
      hidden: true, //没有更多数据了
    };
  },
  methods: {
    // 获取分页列表
    getList() {
      WeGetMySignupActivityPage({ openID: getOpenId() })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].AImgUrl == "") {
                  this.list[i].AImgUrl = "https://img01.yzcdn.cn/vant/cat.jpeg";
                }
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch(() => {});
    },
    getLists() {
      WeGetMySignupActivityPages({ openID: getOpenId() })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.lists = {};
            } else {
              this.lists = res.data.data;
              for (var i = 0; i < this.lists.length; i++) {
                if (this.lists[i].AImgUrl == "") {
                  this.lists[i].AImgUrl = "https://img01.yzcdn.cn/vant/cat.jpeg";
                }
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch(() => {});
    },
    // tan点击切换事件
    onClick(e) {
      this.akId = e;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>